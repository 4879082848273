import React, { useState, useEffect } from "react" 
import axios from "axios"
import wordsToNumbers from 'words-to-numbers';
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router"

const SearchResultSchema = (props) =>{
  const [testimonials, setTestimonials] = useState([]);
  const [renderComponent, setRenderComponent] = useState(false);
  const {pathname} = useLocation();
  const strapiconfig = {
    headers: {
      Authorization:
        `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }

  useEffect(() => {
    let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
    getitems(url);

    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  }, []);


  var ggl_reviews = [];
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig)// could be from env files
      if (data && data.length > 0) {
        data.map((item, index) => {
          //var star_no = wordsToNumbers(item.starRating);
              ggl_reviews.push(item);
        })
      }
      setTestimonials(ggl_reviews);
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }
    var testimonial_count = testimonials ? testimonials.length : 0;
    var rating_count = 0;
    testimonials && testimonials.length > 0 && testimonials.map((item, ind)=>{
        rating_count += parseInt(wordsToNumbers(item.starRating));
    })
    var rating_avg = rating_count/testimonial_count;
    var avgRatingVal = parseFloat(rating_avg).toFixed(1)

    
    var schemadesc = ""
    if(props.page == "searchrent"){
        schemadesc = "Looking for Property for Rent in Doha, Qatar? Explore the wide collection of properties for Rent in Doha, Qatar with The Pearl Gates."
    }else if(props.page == "searchsale"){
        schemadesc = "Looking for Property for Sale in Doha, Qatar? Explore the wide collection of properties for Sale in Doha, Qatar with The Pearl Gates."
    }else if(props.page == "commercialsearchsale"){
        schemadesc = "Looking for Commercial Properties for Sale in Doha, Qatar? Explore the wide collection of properties for Sale in Doha, Qatar with The Pearl Gates."
    }else if(props.page == "commercialsearchrent"){
        schemadesc = "Looking for Commercial Property for Rent in Doha, Qatar? Explore the wide collection of properties for Rent in Doha, Qatar with The Pearl Gates."
    }else if(props.page == "newprojectsearch"){
        schemadesc = props?.schemadesc
    }
   //console.log("schemapropss",avgRatingVal ,props?.schemaAggregateRating)
   return(
    <div className="search-result-schema-template">
      {avgRatingVal > 0 && props?.schemaAggregateRating &&
         <Helmet>
                      <script type="application/ld+json">{`{

                              "@context" : "http://schema.org",
                              "@type" : "Product",
                              "name" : "${props?.title}",
                              "image" : "https://ggfx-pearlgates2.s3.eu-west-2.amazonaws.com/i.prod/pb_logo_2_043eb3ad46.png",
                              "description" : "${schemadesc}",
                              "brand" : {
                                "@type" : "Organization",
                                "name" : "The Pearl Gates"
                              },
                              "aggregateRating": {
                                    "@type": "AggregateRating",
                                    "ratingValue": "${avgRatingVal}",
                                    "reviewCount": "${testimonial_count}"
                                },
                              "offers": {
                                    "@type": "AggregateOffer",
                                    "lowPrice": "${props?.lowPrice}",
                                    "highPrice": "${props?.highPrice}",
                                    "priceCurrency": "QAR"
                                    }

                         }`}</script>
                    </Helmet>}
                    {
                      avgRatingVal > 0 && !props?.schemaAggregateRating &&
                      <Helmet>
                      <script type="application/ld+json">{`{

                              "@context" : "http://schema.org",
                              "@type" : "Product",
                              "name" : "${props?.title}",
                              "image" : "https://ggfx-pearlgates2.s3.eu-west-2.amazonaws.com/i.prod/pb_logo_2_043eb3ad46.png",
                              "description" : "${schemadesc}",
                              "brand" : {
                                "@type" : "Organization",
                                "name" : "The Pearl Gates"
                              },
                              "offers": {
                                    "@type": "AggregateOffer",
                                    "lowPrice": "${props?.lowPrice}",
                                    "highPrice": "${props?.highPrice}",
                                    "priceCurrency": "QAR"
                                    }

                         }`}</script>
                    </Helmet>
                    }
    </div>
   )
}
export default SearchResultSchema