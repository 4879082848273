import { useStaticQuery, graphql } from "gatsby";

export const getPriceStr = ( price ) => {
  let priceStr = '';
  if (price.min && price.max) {
      priceStr = " between " + `QR ` + addCommas(price.min) + " and " + `QR ` + addCommas(price.max);
  } else if (price.max) {
      priceStr = " under " + `QR ` + addCommas(price.max);
  } else if (price.min) {
      priceStr = " over " + `QR ` + addCommas(price.min);
  }
  return priceStr;
}
export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

function addCommas(num) {
  // console.log('num : ' + num);
  var str = num.toString().split('.');
  // console.log('str, ' + str);
  if (str[0].length >= 4) {
      // add comma every 3 digits before decimal
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  /* Optional formating for decimal places
  if (str[1] && str[1].length >= 4) {
      // add space every 3 digits after decimal
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }*/
  return str.join('.');
}

export const ACTION_TYPES = {
  sales: " for Sale in ",
  lettings: " for Rent in ",
}

export const pTypeBase = {
  sales: "/properties/commercial/for-sale/",
  lettings: "/properties/commercial/for-rent/",
}

export const toTitleCase = (text) => {
  let _text = text || '';
  // console.log('_text : ' + _text);
  if ( _text ) {
    _text = _text.trim().split(' ');
    // console.log('_text.length : ' + _text.length);
    if ( _text.length > 0 ) {
      _text = _text.map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    }
  }
  return _text;
}

// TODO: hard coded details to be removed/replaced with common variables
export const parseSearchUrl = (pType, location) => {
  if (location) {
      var query = '';
      let pathUri_main = location.split(pTypeBase[pType].slice(0, -1))
      var areaVal = ''
      var bedVal = ''
      var minpriceVal = ''
      var maxpriceVal = ''
      var typeVal = ''
      var mustIncludeVal = ''
      var saletypeVal = ''
      var furnishVal = ''
      var mustIncludeVal = ''
      var minsizeval = ''
      var maxsizeval = ''
      if( pathUri_main[1] ) { // following could be regexp
        let pathUri = pathUri_main[1].split('/');
        
        //lets loop
        for(let vi = 1; vi <= pathUri.length; vi++ ) {
          // check for area
          if( typeof pathUri[vi] === "undefined" ) {continue;}
          // for-sale
          if ( pathUri[vi].indexOf("for-sale") >= 0 ) {
            // its area
            pType = 'sales';
          }
          if ( pathUri[vi].indexOf("for-rent") >= 0 ) {
            // its area
            pType = 'lettings';
          }
          if ( pathUri[vi].indexOf("in-") == 0 ) {
            // its area
            areaVal = pathUri[vi].replace("in-","").replace("-"," ");
          }
          if ( pathUri[vi].indexOf("-and-more-") >= 0 ) {
            // its bedrooms
            bedVal = pathUri[vi].replace("-and-more-bedrooms","")
          }
          if ( pathUri[vi].indexOf("studio") >= 0 ) {
            // its studio bedroom
            bedVal = 0
          }
          if ( pathUri[vi].indexOf("between-") >= 0 || 
               pathUri[vi].indexOf("over-") >= 0 || 
               pathUri[vi].indexOf("under-") >= 0
             ) {
            // its price
            let priceFilt1 = pathUri[vi].split('over-');
            if( priceFilt1[1] ) {
              minpriceVal = priceFilt1[1]
            }
            let priceFilt2 = pathUri[vi].split('under-');
            if( priceFilt2[1] ) {
              maxpriceVal = priceFilt2[1]
            }
            let priceFilt3 = pathUri[vi].split('between-');
            if( priceFilt3[1] ) {
              let priceFilt4 = priceFilt3[1].split('-and-');
              minpriceVal = priceFilt4[0]
              maxpriceVal = priceFilt4[1]
            }
          }
          // Must Include
          if (pathUri[vi].indexOf("with-") >= 0) {
            var mustIncludeArrfilt = pathUri[vi].replace("with-", "")
            mustIncludeVal = mustIncludeArrfilt.split("-and-")
          }
          let priceFilt5 = pathUri[vi].split('type-');
          if( priceFilt5[1] ) {
            typeVal = priceFilt5[1]
          }

          if (pathUri[vi].indexOf("sale-type-") == 0) {
            // typeVal = pathUri[1]
            saletypeVal = pathUri[vi].replace("sale-type-","");
          } 

          if (pathUri[vi].indexOf("type-") == 0) {
            // typeVal = pathUri[1]
            typeVal = pathUri[vi].replace("type-","");
          }

          if (pathUri[vi].indexOf("furnished-") == 0) {
            // typeVal = pathUri[1]
            furnishVal = pathUri[vi].replace("furnished-","");
          }

          if (pathUri[vi].indexOf("size-") === 0) {
            let replace_size = pathUri[vi].replace("size-","");
            let split_size = replace_size.split("-to-")
            minsizeval = split_size[0];
            maxsizeval = split_size[1];
          }

          if (pathUri[vi].indexOf("above-") == 0) {
            // typeVal = pathUri[1]
            minsizeval = pathUri[vi].replace("above-","");
          }

          if (pathUri[vi].indexOf("below-") == 0) {
            // typeVal = pathUri[1]
            maxsizeval = pathUri[vi].replace("below-","");
          }
        }
      }

    // If no area send default to set default loaction
    // We could send defaut location
    if (!areaVal || areaVal === 'undefined') {
        areaVal = "Doha Qatar"; // TODO
    }

    var areaAlias = areaVal.replace(' ', '-');

    return {
      pType: pType,
      areas: areaVal,
      areas_alias: areaAlias,
      bedrooms: bedVal,
      type: typeVal,
      mustInclude: mustIncludeVal,
      price: {
        min: minpriceVal,
        max: maxpriceVal,
      },
      extra: {
        bedPlus: false,
        areasTitleCase: true
      },
      saletype: saletypeVal,
      furnished: furnishVal,
      minsize: minsizeval,
      maxsize: maxsizeval
    }
  }
}

// TODO: hard coded details to be removed/replaced with common variables
export const propertyH1 = (params) => {
  // console.log('params : ' + params);

  const {areas, bedrooms, pType, type, price, extra, mustInclude, saletype, furnished, minsize, maxsize,} = params;

  let h1 = "";
  let building = "";
  console.log('params : '  , pType, type);
  if ('Commercial' === pType) { // TODO
    // Commercial Property [action] in [default search area] for Sale and Rent
    building = "Commercial ";

    if(furnished){
      building += furnished === "Yes" ? " Furnished " : furnished === "No" ? " Unfurnished " : ` ${capitalize(furnished)} furnished `;
    }

    if(saletype){
      building += ` ${capitalize(saletype)} `;
    }

    if (type)
      building += ` ${type} `;
    else
      building += ` Property `;

      
    h1 += `${building} in `;

    if (areas) {
      h1 += areas;
    }

    h1 += ` for sale and rent`;

  } else {
    let building = "";
    if (bedrooms)
      building = "property";

    if (bedrooms) {
      let bedPlus = extra.bedPlus ? '+' : '+';
      h1 += bedrooms + bedPlus + " Bedroom ";
    }

    if(furnished){
      building += furnished === "Yes" ? " Furnished " : furnished === "No" ? " Unfurnished " : ` ${capitalize(furnished)} furnished `;
    }

    if(saletype){
      building += ` ${capitalize(saletype)} `;
    }

    if (type) {
      building += `${type} `;
    } else {
      building += `Commercial property `;
    }

    h1 += toTitleCase(building);
    h1 += ACTION_TYPES[pType];

    if (areas) {
      let _areas = areas;
      _areas = _areas.replace("-"," ");
      _areas = decodeURIComponent(_areas);
      _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
      h1 += _areas
    }

    if ( price && (price.min || price.max) )  {
      h1 += getPriceStr(price);
    }

    if ( mustInclude && mustInclude ) {
      h1 += " with "+mustInclude.join('-and-')
    }

    if ( minsize && maxsize ) {
      h1 += ` with property size between ${minsize} Sqm to ${maxsize} Sqm`;
    } else if(minsize){
      h1 += ` with property size above ${minsize} Sqm`;
    } else if(maxsize){
      h1 += ` with property size below ${maxsize} Sqm`;
    }
  }

  // console.log("H1 title => ", h1);
  return h1;
}